<template>
  <div class="section-footer wf-section">
    <div class="container-content w-container">
      <div class="footer-colum-block w-row">
        <div class="footer-column w-col w-col-6">
          <img
            src="/images/WARDEN-LOGO-FOOTER.svg"
            loading="lazy"
            alt="WARDENSWAP"
            class="footer-logo"
          />
          <p class="footer-txt-brand-description">
            You Swap, AI does the rest. <br />We are the first best-rate AI swap
            with the lowest gas fees on multi-chains.<br />
          </p>
        </div>
        <div class="footer-column right w-col w-col-6">
          <div class="footer-link-block">
            <h3 class="footer-h3">Official Channels</h3>
            <a
              href="https://twitter.com/WardenSwap"
              target="_blank"
              class="footer-txt-link"
              >Twitter</a
            >
            <a
              href="https://medium.com/wardenofficial"
              target="_blank"
              class="footer-txt-link"
              >Medium</a
            >
            <a
              href="https://discord.com/invite/NdBymRxmxa"
              target="_blank"
              class="footer-txt-link"
              >Discord</a
            >
            <a
              href="https://t.me/wardenfi"
              target="_blank"
              class="footer-txt-link"
              >Telegram</a
            >
            <a
              href="https://www.facebook.com/wardenswap"
              target="_blank"
              class="footer-txt-link"
              >Facebook</a
            >
            <a
              href="https://coinmarketcap.com/currencies/wardenswap/"
              target="_blank"
              class="footer-txt-link"
              >CoinMarketCap</a
            >
            <a
              href="https://www.coingecko.com/th/coin/warden"
              target="_blank"
              class="footer-txt-link"
              >CoinGecko</a
            >
            <a
              href="https://wardenswap.com/blog"
              target="_blank"
              class="footer-txt-link"
              >Blog</a
            >
          </div>
          <div class="footer-link-block">
            <h3 class="footer-h3">About</h3>
            <a
              href="https://bit.ly/3yUBPZX"
              target="_blank"
              class="footer-txt-link"
              >Documentation</a
            >
            <a
              href="https://github.com/WardenSwap"
              target="_blank"
              class="footer-txt-link"
              >Github</a
            >
            <a
              href="https://www.certik.com/projects/wardenswap"
              target="_blank"
              class="footer-txt-link"
              >Audit Report Certik</a
            >
            <a
              href="https://github.com/valixconsulting/audit-reports/blob/main/ValixConsulting-Audit-Report-WardenFinance-Aegis-and-AegisL2-v1.0.pdf"
              target="_blank"
              class="footer-txt-link"
              >Audit Report Valix</a
            >
            <a href="/branding" target="_blank" class="footer-txt-link"
              >Branding</a
            >
            <a
              href="https://dashboard.wardenswap.finance/?t=0&amp;s=1#/"
              target="_blank"
              class="footer-txt-link"
              >Dashboard</a
            >
            <a
              href="https://www.wardenswap.finance/nft/"
              target="_blank"
              class="footer-txt-link"
              >MetaWarden NFT</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
