<template>
  <div id="top" class="hero-section branding wf-section">
    <div class="header-social-block">
      <div class="container-content w-container">
        <div class="header-social-txt">OFFICIAL WEBSITE OF WARDEN</div>
        <a
          href="https://github.com/WardenSwap"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_github_white.svg"
            loading="lazy"
            alt="Github"
            class="social-img"
        /></a>
        <a
          href="https://t.me/wardenfi"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_tg_white.svg"
            loading="lazy"
            alt="Telegram"
            class="social-img"
        /></a>
        <a
          href="https://medium.com/wardenofficial"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_medium.svg"
            loading="lazy"
            alt="Medium"
            class="social-img"
        /></a>
        <a
          href="https://twitter.com/WardenSwap"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_twitter.svg"
            loading="lazy"
            alt="Twitter"
            class="social-img"
        /></a>
        <a
          href="https://www.facebook.com/wardenswap"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_fb.svg"
            loading="lazy"
            alt="Facebook Page"
            class="social-img"
        /></a>
        <a
          href="https://discord.com/invite/NdBymRxmxa"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_discord.svg"
            loading="lazy"
            alt="Discord"
            class="social-img"
        /></a>
        <a
          href="https://github.com/valixconsulting/audit-reports/blob/main/ValixConsulting-Audit-Report-WardenFinance-Aegis-and-AegisL2-v1.0.pdf"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_valix.svg"
            loading="lazy"
            alt="Valix"
            class="social-img"
        /></a>
        <a
          href="https://www.certik.com/projects/wardenswap"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_certik.svg"
            loading="lazy"
            alt="Certik"
            class="social-img"
        /></a>
        <a
          href="https://www.coingecko.com/th/coin/warden"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_coingekko.svg"
            loading="lazy"
            alt="Coingekko"
            class="social-img"
        /></a>
        <a
          href="https://coinmarketcap.com/currencies/wardenswap/"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_cmc.svg"
            loading="lazy"
            alt="Coinmaketcap"
            class="social-img"
        /></a>
      </div>
    </div>
    <div class="container-content w-container">
      <img
        src="/images/WARDEN-LOGO-HEADER.svg"
        loading="lazy"
        alt="WARDEN SWAP"
        class="_w-logo-header"
      />
      <a
        href="https://www.wardenswap.finance/"
        target="_blank"
        class="btn-launch-app w-inline-block"
      >
        <div class="text-block-2">Launch App</div>
      </a>
      <div v-if="price" class="wad-price-txt">${{ price }}</div>
      <div v-else class="skeleton loading" />
      <img
        src="/images/WAD_chain_bsc.svg"
        loading="lazy"
        alt=""
        class="wad-price-img"
      />
    </div>
    <div class="container-content hero branding w-container">
      <img
        src="/images/hero-golden-ratio.svg"
        loading="lazy"
        alt=""
        class="hero-logo-golden-ratio"
      />
      <h1 class="hero-h1-txt branding">
        WardenSwap <span class="topic-txt-gradient header">Branding</span>
      </h1>
      <div class="hero-motto-txt branding">
        Our brand values are built through our community.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: String,
      default: "0.0",
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton:empty {
  width: 60px;
  height: 25px;
  display: inline-block;
  margin-top: 50px;
  margin-right: 37px;
  margin-left: 10px;
  float: right;

  cursor: progress;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  background-position: -60px 0, 0px 0px;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  to {
    background-position: 60px 0, 0px 0px;
  }
}
</style>
