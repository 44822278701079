<template>
  <div class="section-golden-ratio wf-section">
    <div class="container-content w-container">
      <h2 class="h2-title mobile-title">
        Warden Logo with <span class="topic-txt-gradient">Golden Ratio</span>
      </h2>
      <div class="golden-ratio-column-main w-row">
        <div
          class="golden-ratio-col w-col w-col-6 w-col-small-6 w-col-tiny-tiny-stack"
        >
          <img
            src="/images/MAIN-LOGO.svg"
            loading="lazy"
            alt=""
            class="golden-ratio-main-logo-img"
          />
        </div>
        <div
          class="golden-ratio-col w-col w-col-6 w-col-small-6 w-col-tiny-tiny-stack"
        >
          <img
            src="/images/warden-golden-ratio.svg"
            loading="lazy"
            alt=""
            class="golden-ratio-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
