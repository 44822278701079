<template>
  <div class="section-theme-color wf-section">
    <div class="container-content w-container">
      <h2 class="h2-title mobile-title">
        Warden <span class="topic-txt-gradient">Theme Colors</span>
      </h2>
      <div class="_3-column-main w-row">
        <div class="_3-column w-col w-col-4 w-col-small-4">
          <div class="wrap-theme-block primary-color"></div>
          <div class="theme-color-name-txt">Primary color</div>
          <div class="theme-color-code-txt">
            Hex : #202052<br />RGB : 32,32,82<br />CMYK : 100,98,36,33
          </div>
        </div>
        <div class="_3-column w-col w-col-4 w-col-small-4">
          <div class="wrap-theme-block second-color"></div>
          <div class="theme-color-name-txt">Secondary color</div>
          <div class="theme-color-code-txt">
            Hex : #FFFFFF<br />RGB : 255,255,255<br />CMYK : 0,0,0,0
          </div>
        </div>
        <div class="_3-column w-col w-col-4 w-col-small-4">
          <div class="wrap-theme-block bg-color"></div>
          <div class="theme-color-name-txt">Background color</div>
          <div class="theme-color-code-txt">
            Hex : #131135<br />RGB : 19,17,53<br />CMYK : 96,94,45,58
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
