<template>
  <div>
    <Top :price="price" />
    <SectionWardenLogo />
    <SectionGoldenRatio />
    <SectionThemeColor />
    <SectionMetaWarden />
    <Footer />
  </div>
</template>

<script>
import Top from "./components/Top.vue";
import SectionWardenLogo from "./components/SectionWardenLogo.vue";
import SectionGoldenRatio from "./components/SectionGoldenRatio.vue";
import SectionThemeColor from "./components/SectionThemeColor.vue";
import SectionMetaWarden from "./components/SectionMetaWarden.vue";
import Footer from "./components/Footer.vue";
import { fetchWardenPrice } from "@/helpers/warden-price.js";

export default {
  name: "Branding",

  components: {
    Top,
    SectionWardenLogo,
    SectionGoldenRatio,
    SectionThemeColor,
    SectionMetaWarden,
    Footer,
  },

  data: () => ({
    price: null,
  }),

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        const price = await fetchWardenPrice();
        this.price = price;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
