<template>
  <div class="section-metawarden wf-section">
    <div class="container-content w-container">
      <h2 class="h2-title mobile-title">
        MetaWarden <span class="topic-txt-gradient">Logos</span>
      </h2>
        <div class="_4-column-main w-row">
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6 w-col-tiny-tiny-stack">
          <div class="wrap-branding-bg-white"><img src="/images/metawarden-logo-dark-color.png" loading="lazy" width="212.5" alt="MetaWarden logo. Primary theme color"></div>
          <p class="col-desc-txt mwad-logo">MetaWarden logo. Primary theme color, suitable for white or light backgrounds.</p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a href="https://www.wardenswap.com/utilityfiles/branding/metaWardenLogos/metawarden-logo-dark-color.png" target="_blank" class="btn-download-branding w-inline-block">
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6 w-col-tiny-tiny-stack">
          <div class="wrap-branding-bg-primary"><img src="/images/metawarden-logo-white-color.png" loading="lazy" width="212.5" alt="MetaWarden logo, white theme"></div>
          <p class="col-desc-txt mwad-logo">MetaWarden logo, white theme, suitable for black or dark backgrounds.</p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a href="https://www.wardenswap.com/utilityfiles/branding/metaWardenLogos/metawarden-logo-white-color.png" target="_blank" class="btn-download-branding w-inline-block">
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6 w-col-tiny-tiny-stack">
          <div class="wrap-branding-bg-white"><img src="/images/metawarden_v2dark.png" loading="lazy" width="168.5" alt="MetaWarden logo v2, Primary theme color, suitable for white or light backgrounds." class="logo-mwad-v2"></div>
          <p class="col-desc-txt mwad-logo">MetaWarden logo v2, Primary theme color, suitable for white or light backgrounds.</p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a href="https://www.wardenswap.com/utilityfiles/branding/metaWardenLogos/metawarden_version2dark.png" target="_blank" class="btn-download-branding w-inline-block">
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6 w-col-tiny-tiny-stack">
          <div class="wrap-branding-bg-primary"><img src="/images/metawarden_v2white.png" loading="lazy" width="168" alt="MetaWarden logo v2, white theme, suitable for black or dark backgrounds." class="logo-mwad-v2"></div>
          <p class="col-desc-txt mwad-logo">MetaWarden logo v2, white theme, suitable for black or dark backgrounds.</p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a href="https://www.wardenswap.com/utilityfiles/branding/metaWardenLogos/metawarden_version2.png" target="_blank" class="btn-download-branding w-inline-block">
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
      </div>
      <div class="_4-column-main mwad-col-logo w-row">
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6 w-col-tiny-tiny-stack">
          <div class="wrap-branding-bg-white"><img src="/images/mwad_dark.png" loading="lazy" width="189" alt="MWAD logo, Primary theme color, suitable for white or light backgrounds." class="logo-mwad-v2"></div>
          <p class="col-desc-txt mwad-logo">MWAD logo, Primary theme color, suitable for white or light backgrounds.</p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a href="https://www.wardenswap.com/utilityfiles/branding/metaWardenLogos/mwad_logo_dark.png" target="_blank" class="btn-download-branding w-inline-block">
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6 w-col-tiny-tiny-stack">
          <div class="wrap-branding-bg-primary"><img src="/images/mwad_white.png" loading="lazy" width="190" alt="MWAD logo, white theme, suitable for black or dark backgrounds." class="logo-mwad-v2"></div>
          <p class="col-desc-txt mwad-logo">MWAD logo, white theme, suitable for black or dark backgrounds.</p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a href="https://www.wardenswap.com/utilityfiles/branding/metaWardenLogos/mwad_logo.png" target="_blank" class="btn-download-branding w-inline-block">
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6 w-col-tiny-tiny-stack">
          <div class="wrap-branding-bg-white"><img src="/images/metawarden_partner_dark.png" loading="lazy" width="118.5" alt="MetaWarden logo for partner, Primary theme color, suitable for white or light backgrounds." class="logo-partner-mwad"></div>
          <p class="col-desc-txt mwad-logo">MetaWarden logo for partner, Primary theme color, suitable for white or light backgrounds.</p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a href="https://www.wardenswap.com/utilityfiles/branding/metaWardenLogos/metawarden_partner_dark.png" target="_blank" class="btn-download-branding w-inline-block">
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6 w-col-tiny-tiny-stack">
          <div class="wrap-branding-bg-primary"><img src="/images/metawarden_partner_white.png" loading="lazy" width="118" alt="MetaWarden logo for partner, white theme, suitable for black or dark backgrounds." class="logo-partner-mwad"></div>
          <p class="col-desc-txt mwad-logo">MetaWarden logo for partner, white theme, suitable for black or dark backgrounds.</p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a href="https://www.wardenswap.com/utilityfiles/branding/metaWardenLogos/metawarden_partner.png" target="_blank" class="btn-download-branding w-inline-block">
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
      </div>


      <h2 class="h2-title mobile-title">
        MetaWarden <span class="topic-txt-gradient">Partner Templates</span>
      </h2>
      <div class="_3-column-main w-row">
        <div class="_3-column w-col w-col-4">
          <div class="wrap-branding-bg-white">
            <img
              src="/images/template-meta.png"
              loading="lazy"
              alt="Template for placing partner logo or text. Recommended for logo or text with bright theme colors"
              width="231.5"
            />
          </div>
          <p class="col-desc-txt">
            Template for placing partner logo or text. Recommended for logo or
            text with bright theme colors<br />
          </p>
          <div class="btn-download-column-main w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenPartnerTemplates/partner3.psd"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PSD</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenPartnerTemplates/templat-meta.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
          </div>
        </div>
        <div class="_3-column w-col w-col-4">
          <div class="wrap-branding-bg-white">
            <img
              src="/images/partner-dark-theme.png"
              loading="lazy"
              alt="Template for placing partner logo or &nbsp;text. Recommended for logo or text with bright theme colors"
              width="231.5"
            />
          </div>
          <p class="col-desc-txt">
            Template for placing partner logo or &nbsp;text. Recommended for
            logo or text with bright theme colors
          </p>
          <div class="btn-download-column-main w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenPartnerTemplates/partner-dark-theme.psd"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PSD</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenPartnerTemplates/partner-dark-theme.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
          </div>
        </div>
        <div class="_3-column w-col w-col-4">
          <div class="wrap-branding-bg-white">
            <img
              src="/images/partner-color-theme.png"
              loading="lazy"
              alt="Template for placing partner logo or &nbsp;text. Recommended for logo or text with dark theme colors"
              width="231.5"
            />
          </div>
          <p class="col-desc-txt">
            Template for placing partner logo or &nbsp;text. Recommended for
            logo or text with dark theme colors
          </p>
          <div class="btn-download-column-main w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenPartnerTemplates/partner-color-theme.psd"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PSD</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenPartnerTemplates/partner-color-theme.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <h2 class="h2-title mobile-title">
        MetaWarden <span class="topic-txt-gradient">Characters</span>
      </h2>
      <div class="_4-column-main w-row">
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/common-front.png"
            loading="lazy"
            alt="MetaWarden Common (front)"
            width="250"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Common (front)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/common/warden_front.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/common-side.png"
            loading="lazy"
            width="250"
            alt="MetaWarden Common (side)"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Common (side)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/common/warden_front(1).png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/common-back.png"
            loading="lazy"
            width="250"
            alt="MetaWarden Common (back)"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Common (back)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/common/warden_back.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/common-half.png"
            loading="lazy"
            width="250"
            alt="MetaWarden Common (half body)"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Common (half body)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/common/warden_half.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
      </div>
      <div class="_4-column-main w-row">
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/super-front.png"
            loading="lazy"
            width="250"
            alt="MetaWarden Super (front)"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Super (front)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/super/super_full_front.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/super-side.png"
            loading="lazy"
            width="250"
            alt="MetaWarden Super (side)"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Super (side)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/super/super_front.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/super-back.png"
            loading="lazy"
            width="250"
            alt="MetaWarden Super (back)"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Super (back)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/super/super_back.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/super-half.png"
            loading="lazy"
            width="250"
            alt="MetaWarden Super (half body)"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Super (half body)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/super/super_half.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
      </div>
      <div class="_4-column-main w-row">
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/gold-front.png"
            loading="lazy"
            alt="MetaWarden Gold (front)"
            width="250"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Gold (front)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/gold/gold_front.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/gold-side.png"
            loading="lazy"
            width="250"
            alt="MetaWarden Gold (side)"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Gold (side)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/gold/gold_front_Final.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/gold-back.png"
            loading="lazy"
            width="250"
            alt="MetaWarden Gold (back)"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Gold (back)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/gold/gold_back.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <img
            src="/images/gold-half.png"
            loading="lazy"
            width="250"
            alt="MetaWarden Gold (half body)"
          />
          <p class="col-desc-txt mwad-character">
            MetaWarden Gold (half body)<br />
          </p>
          <div class="btn-download-column-main _1btn w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/metaWardenCharacters/gold/golden_half_final.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
