<template>
  <div class="section-why-wardenswap branding wf-section">
    <div class="container-content w-container">
      <div class="zip-div-block">
        <div class="zip-column-main w-row">
          <div class="zip-col w-col w-col-8">
            <div class="zip-heading-txt">
              Download <span class="topic-txt-gradient">All Assets</span>
            </div>
            <div class="zip-text">Get all types of logos (.svg and .png)</div>
          </div>
          <div class="zip-col w-clearfix w-col w-col-4">
            <a
              href="https://www.wardenswap.com/utilityfiles/branding/branding.zip"
              target="_blank"
              class="_w-btn _w-sdk w-inline-block"
            >
              <div>Download (.zip)</div>
            </a>
          </div>
        </div>
      </div>
      <h2 class="h2-title mobile-title">
        Warden <span class="topic-txt-gradient">Logos</span>
      </h2>
      <div class="_4-column-main w-row">
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <div class="wrap-branding-bg-white">
            <img
              src="/images/horizontal_logo_warden_text_navy.svg"
              loading="lazy"
              alt="warden horizontal logo  primary theme color"
              class="h-logo-primary"
            />
          </div>
          <p class="col-desc-txt mwad-logo-desc">
            Horizontal logo. Primary theme color, suitable for white or light
            backgrounds.
          </p>
          <div class="btn-download-column-main w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/wardenLogos/horizontalLogo/warden-logo-primary-color.svg"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.SVG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/wardenLogos/horizontalLogo/warden-logo-Primary-color.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <div class="wrap-branding-bg-primary">
            <img
              src="/images/horizontal_logo_warden_text_white.svg"
              loading="lazy"
              alt="warden horizontal logo white theme"
              class="h-logo-white"
            />
          </div>
          <p class="col-desc-txt mwad-logo-desc">
            Horizontal logo, white theme, suitable for black or dark
            backgrounds.
          </p>
          <div class="btn-download-column-main w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/wardenLogos/horizontalLogo/warden-logo-white-color.svg"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.SVG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/wardenLogos/horizontalLogo/warden-logo-white-color.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <div class="wrap-branding-bg-white">
            <img
              src="/images/logo-vertical-primary.svg"
              loading="lazy"
              alt="Vertical logo, primary theme color"
              class="v-logo-primary"
            />
          </div>
          <p class="col-desc-txt mwad-logo-desc">
            Vertical logo, primary theme color, suitable for white or light
            backgrounds.
          </p>
          <div class="btn-download-column-main w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/wardenLogos/verticalLogo/warden-logo-vertical-primary-color.svg"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.SVG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/wardenLogos/verticalLogo/warden-logo-vertical-primary-color.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
          </div>
        </div>
        <div class="_4-column w-col w-col-3 w-col-medium-6 w-col-small-6">
          <div class="wrap-branding-bg-primary">
            <img
              src="/images/logo-vertical-white.svg"
              loading="lazy"
              alt="Vertical logo, white theme"
              class="v-logo-white"
            />
          </div>
          <p class="col-desc-txt mwad-logo-desc">
            Vertical logo, white theme, suitable for black or dark backgrounds.
          </p>
          <div class="btn-download-column-main w-row">
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/wardenLogos/verticalLogo/warden-logo-vertical-white-color.svg"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.SVG</div>
              </a>
            </div>
            <div class="btn-download-col w-col w-col-6">
              <a
                href="https://www.wardenswap.com/utilityfiles/branding/wardenLogos/verticalLogo/warden-logo-vertical-white-color.png"
                target="_blank"
                class="btn-download-branding w-inline-block"
              >
                <div>.PNG</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
